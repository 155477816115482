import Vue from 'vue'
import VueLazyload from 'vue-lazyload'

const loadimage = require('~/assets/img/lazy_loading.gif')

Vue.use(VueLazyload, {
  preLoad: 2,
  loading: loadimage,
  observer: true,
  lazyComponent: true
})
