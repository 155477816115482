export const state = () => ({
  karteTrackingItems: {},
  companyName: ''
})

export const mutations = {
  SET_KARTE_TRACKING_ITEM(state, trackingItems) {
    const sendData = {
      url: trackingItems.currentUrl.url,
      title: trackingItems.entryKarteTracking.title
        ? trackingItems.entryKarteTracking.title
        : trackingItems.entryKarteTracking.companyName,
      actionBtns: trackingItems.entryKarteTracking.actionBtns
    }

    state.karteTrackingItems = sendData
    state.companyName = trackingItems.entryKarteTracking.companyName
  }
}

export const actions = {
  setKarteTrackingItem({ commit }, trackingItems) {
    commit('SET_KARTE_TRACKING_ITEM', trackingItems)
  }
}
