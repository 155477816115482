export const state = () => ({
  currentDate: null
})

export const mutations = {
  SET_SERVER_TIMESTAMP(state, timestamp) {
    state.currentDate = this.$dayjs(timestamp)
  }
}

export const actions = {
  getServerTimestamp({ commit, state }, timestamp) {
    if (!state.currentDate) commit('SET_SERVER_TIMESTAMP', timestamp)
  }
}
