import Cookies from 'universal-cookie'
import camelcaseKeys from 'camelcase-keys'
import authSetting from '~/config/authSetting'
import { API } from '~/types/api'
import { Stories } from '~/types/stories'

export const state = () => ({
  noSearchResult: false,
  searchTotalCount: null,
  searchResults: [],
  currentTab: null
})

export const mutations = {
  SET_SEARCH_RESULTS(state, response: Stories.InfinityLoadArticles) {
    if (!response.records) {
      state.noSearchResult = true
    } else {
      state.searchTotalCount = response.pageMeta.totalCount
      state.searchResults = response.records
      state.noSearchResult = false
    }
  },
  RESET_NORESULT_STATE(state) {
    state.searchResults = []
    state.noSearchResult = false
  },
  SET_CURRENT_TAB(state, tab: number) {
    state.currentTab = tab
  }
}

export const actions = {
  async getSearchResults({ commit }, url: string) {
    const cookies = new Cookies()
    const config = {
      auth: authSetting,
      withCredentials: true
    }
    const results = await this.$axios.$get(url, config).catch((err) => {
      // APIレスポンスが401だった場合
      const unauthorized = err.response.status === 401
      if (unauthorized) {
        cookies.set('unauthorized_error', true)
        logout()
      }
    })
    commit('SET_SEARCH_RESULTS', camelcaseKeys(results, { deep: true }))
    async function logout() {
      const csrfUrl = process.env.API_URL + '/members/get_csrf_token'
      try {
        await this.$axios
          .$get(csrfUrl, {
            auth: authSetting,
            withCredentials: true
          })
          .then((token: API.CSRF) => {
            logoutAction(token.csrf_token)
          })
      } catch (e) {
        return e
      }
    }

    async function logoutAction(csrf: string) {
      const url = process.env.API_URL + '/members/logout'
      const config = {
        headers: {
          'X-CSRF-Token': csrf
        },
        auth: authSetting,
        withCredentials: true
      }
      try {
        await this.$axios
          .$patch(url, {}, config)
          .then(() => {
            // sessionStorageのmemberId削除
            sessionStorage.removeItem('member')
          })
          .then(() => {
            const redirectUrl = `/`
            location.href = redirectUrl
          })
      } catch (e) {
        return e
      }
    }
  },
  changeNoresultState({ commit }) {
    commit('RESET_NORESULT_STATE')
  },
  updateCurrentTab({ commit }, tab: number) {
    commit('SET_CURRENT_TAB', tab)
  }
}
