export const state = () => ({
  featureArticles: {}
})

export const mutations = {
  SET_FEATURE_ARTICLES(state, res) {
    state.featureArticles = res
  }
}

export const actions = {
  getFeatureArticles({ commit }, res: any) {
    commit('SET_FEATURE_ARTICLES', res)
  }
}
