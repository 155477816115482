
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class DialogCard extends Vue {
  @Prop({
    type: String,
    required: false
  })
  private classname!: string

  @Prop({
    type: String,
    required: false
  })
  private modalTitle!: string

  @Prop({
    type: Boolean,
    required: false
  })
  private noneCrossMark!: boolean

  @Prop({
    type: Boolean,
    required: false,
    default: false
  })
  private search!: boolean
}
