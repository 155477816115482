import authSetting from '~/config/authSetting'

export default ({ app, store }) => {
  const url = process.env.API_URL + `/members/auth_chk`
  const config = {
    auth: authSetting,
    withCredentials: true
  }

  // sessionStorageに保存、dispatchしてメンバー情報を更新
  const saveMemberData = (id) => {
    sessionStorage.setItem('member', JSON.stringify({ id }))
    store.dispatch('notifications/getNotifications', id)
    store.dispatch('member/getMemberFollowing', id)
    store.dispatch('authentication/updateMembersHeader', true)
  }

  const clearMemberData = () => {
    sessionStorage.removeItem('member')
    store.dispatch('authentication/updateMembersHeader', false)
  }

  // APIでメンバーの認証を確認し、login済みの場合にはメンバー情報をハンドル
  const verifyAndStoreMemberData = async () => {
    try {
      const results = await app.$axios.get(url, config)
      if (results.data.status === 200) {
        saveMemberData(results.data.id)
        return results.data.id
      } else if (results.data.status === 401) {
        clearMemberData()
        return null
      }
    } catch (error) {
      clearMemberData()
      return null
    }
  }

  app.router.beforeEach(async (to, _, next) => {
    const redirectUrl = '/'
    const member = JSON.parse(sessionStorage.getItem('member'))
    const isRestrictedPage =
      (to.path.startsWith('/members/') &&
        !to.path.startsWith('/members/activate/')) ||
      to.name === 'info-id'

    if (member) {
      // sessionStorageにmemberIdがある場合はheaderをMemberHeaderに変更(Emailログイン時)
      store.dispatch('notifications/getNotifications', member.id)
      store.dispatch('member/getMemberFollowing', member.id)
      store.dispatch('authentication/updateMembersHeader', true)
      next()
    } else if (isRestrictedPage) {
      // sessionStorageにmember_idがない場合はAPIをコールしてmember_idを再取得できるか試行
      const memberId = await verifyAndStoreMemberData()
      memberId ? next() : (location.href = redirectUrl)
    } else {
      // memberがない場合にAPIをコールして確認
      await verifyAndStoreMemberData()
      next()
    }
  })
}
