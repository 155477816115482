export default async function ({ store }) {
  if (process.client) {
    const member = sessionStorage.getItem('member')
    if (member) {
      const parseData = JSON.parse(member)
      parseData.id
        ? await store.dispatch('authentication/updateMembersHeader', true)
        : await store.dispatch('authentication/updateMembersHeader', false)
    }
  }
}
