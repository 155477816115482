
import { Component, Vue } from 'vue-property-decorator'
import UniversalCookie from 'universal-cookie'
import authSetting from '~/config/authSetting'
import { API } from '~/types/api'

@Component
export default class LoginBtn extends Vue {
  private get auth() {
    return this.$store.state.authentication.membersHeader
  }

  private get iconClass() {
    return this.auth ? 'icon-font-icon_logout' : 'icon-font-icon_login'
  }

  private get buttonText() {
    return this.auth ? 'ログアウト' : 'ログイン'
  }

  private handleClick() {
    if (this.auth) {
      this.logout()
    } else {
      this.login()
    }
  }

  private async logout() {
    const csrfUrl = process.env.API_URL + '/members/get_csrf_token'
    try {
      const token: API.CSRF = await this.$axios.$get(csrfUrl, {
        auth: authSetting,
        withCredentials: true
      })
      await this.logoutAction(token.csrf_token)
    } catch (e) {
      return e
    }
  }

  private async logoutAction(csrf: string) {
    const cookies = new UniversalCookie()
    const url = process.env.API_URL + '/members/logout'
    const config = {
      headers: {
        'X-CSRF-Token': csrf
      },
      auth: authSetting,
      withCredentials: true
    }
    try {
      await this.$axios.$patch(url, {}, config)
      cookies.remove('unauthorized_error')
      cookies.remove('quitMessage')
      sessionStorage.removeItem('member')
      location.href = `/login?logout=true`
    } catch (e) {
      return e
    }
  }

  private login() {
    location.href = '/login'
  }
}
