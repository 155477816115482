
import { Component, Vue } from 'vue-property-decorator'

@Component
export default class NonMemberModal extends Vue {
  private modalClose(): void {
    localStorage.removeItem('beforePath')
    this.$store.dispatch('modal/changeNonMemberModalState', false)
  }
}
