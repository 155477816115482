import camelcaseKeys from 'camelcase-keys'
import authSetting from '~/config/authSetting'
import { errorHandler } from '~/lib/errorHandling'

export const state = () => ({
  allTags: [],
  selectTag: {},
  categorization: [],
  selectedFilterCategories: [],
  articleCategoryFilterPreCheck: [],
  categoryList: [
    {
      id: 1,
      name: 'キャリアパス',
      order: 2
    },
    {
      id: 2,
      name: 'カルチャー',
      order: 7
    },
    {
      id: 3,
      name: '制度',
      order: 6
    },
    {
      id: 4,
      name: '創業・起業',
      order: 8
    },
    {
      id: 5,
      name: '事業・経営',
      order: 5
    },
    {
      id: 8,
      name: '社会',
      order: 9
    },
    {
      id: 9,
      name: 'その他',
      order: 10
    },
    {
      id: 10,
      name: '職種',
      order: 1
    },
    {
      id: 11,
      name: '働き方',
      order: 4
    },
    {
      id: 12,
      name: '仕事への思い',
      order: 3
    }
  ]
})

export const getters = {
  categorization(state) {
    const newCategory = []
    const orderedCategories = state.categoryList.sort(
      (a, b) => a.order - b.order
    )
    orderedCategories.filter((x: any) => {
      const sameCategory = state.allTags.filter((v: any) => {
        v.isSelected = false
        return v.categoryId === x.id
      })
      if (sameCategory.length) {
        const categoryItem = {
          categoryName: x.name,
          records: [...sameCategory]
        }
        newCategory.push(categoryItem)
      }
    })
    return newCategory
  }
}

export const mutations = {
  SET_ALL_TAGS(state, tags) {
    state.allTags = tags
  },
  SET_SELECT_TAG(state, tags) {
    state.selectTag = tags
  },
  SET_CATEGORIZATION(state) {
    const newCategory = []
    const orderedCategories = state.categoryList.sort(
      (a, b) => a.order - b.order
    )
    orderedCategories.filter((x: any) => {
      const sameCategory = state.allTags.filter((v: any) => {
        v.isSelected = false
        return v.categoryId === x.id
      })
      if (sameCategory.length) {
        const categoryItem = {
          categoryName: x.name,
          records: [...sameCategory]
        }
        newCategory.push(categoryItem)
      }
    })
    state.categorization = newCategory
  },
  SET_SELECTED_FILTER_CATEGORY(state, category) {
    const selectedIds = state.selectedFilterCategories.map((v: any) => v.id)
    const alreadySelected = selectedIds.includes(category.id)
    if (!alreadySelected) {
      state.selectedFilterCategories.push(category)
      state.categorization.filter((v: any) => {
        v.records.filter((x: any, i: number) => {
          if (x.id === category.id) {
            const obj = {
              categoryId: x.categoryId,
              id: x.id,
              isSelected: true,
              name: x.name,
              slug: x.slug
            }
            v.records.splice(i, 1, obj)
          }
        })
      })
    } else {
      state.selectedFilterCategories.filter((v: any, i: number) => {
        if (v.id === category.id) state.selectedFilterCategories.splice(i, 1)
      })
      state.categorization.filter((v: any) => {
        v.records.filter((x: any) => {
          if (x.id === category.id) x.isSelected = false
        })
      })
    }
  },
  REMOVE_SELECTED(state) {
    state.selectedFilterCategories = []
    state.categorization.filter((v: any) =>
      v.records.filter((x: any) => (x.isSelected = false))
    )
  },
  SET_ARTICLE_CATEGORY_FILTER_PRE_CHECK(state, current) {
    state.articleCategoryFilterPreCheck = current
    state.selectedFilterCategories = []
    state.categorization.map((v: any) => {
      v.records.filter((x: any) => {
        x.isSelected = false
        current.map((e: any) => {
          if (x.id === e.id) {
            x.isSelected = true
            state.selectedFilterCategories.push(e)
          }
        })
      })
    })
  }
}

export const actions = {
  async getAllTags({ state, commit }, context: any) {
    if (!state.allTags.length) {
      const url = process.env.API_URL + '/tags'
      const allTags = await this.$axios
        .$get(url, { auth: authSetting })
        .catch((err) => {
          errorHandler(err, context)
          return err
        })
      if (allTags.length)
        commit('SET_ALL_TAGS', camelcaseKeys(allTags, { deep: true }))
      commit('SET_CATEGORIZATION')
    }
  },
  async getSelectedTag({ state, commit }, slug: string) {
    if (state.selectTag.slug !== slug) {
      const url = process.env.API_URL + '/tags/' + encodeURI(slug)
      const selectTag = await this.$axios.$get(url, { auth: authSetting })
      commit('SET_SELECT_TAG', camelcaseKeys(selectTag, { deep: true }))
    }
  },
  selectedFilterCategory({ commit }, select: any) {
    commit('SET_SELECTED_FILTER_CATEGORY', select)
  },
  removeSelected({ commit }) {
    commit('REMOVE_SELECTED')
  },
  preCheckedCategories({ commit }, category: any) {
    category.map((v: any) => commit('SET_SELECTED_FILTER_CATEGORY', v))
  },
  articleCategoryFilterPreChecked({ commit }, category: any) {
    if (category.length) {
      commit('SET_ARTICLE_CATEGORY_FILTER_PRE_CHECK', category)
    } else {
      commit('REMOVE_SELECTED')
    }
  }
}
