import camelcaseKeys from 'camelcase-keys'
import authSetting from '~/config/authSetting'
import { errorHandler } from '~/lib/errorHandling'

export const state = () => ({
  showLoading: true, // searchのloadingSpiner
  noQuery: false, // 検索wordがない場合の表示
  openPanels: [], // アコーディオンパネルの開閉状況
  noSearchResult: false,
  searchResults: [],
  searchTotalCount: 0,
  suggestData: null
})

export const getters = {
  filteredSuggestions: (state) => (searchWord) => {
    if (!state.suggestData) return { companies: [], categories: [] }

    const searchWords = searchWord.toLowerCase().split(' ').filter(Boolean)

    // nameでフィルタリング
    const nameFilteredCompanies = state.suggestData.companies
      .filter((company) =>
        searchWords.every((word) => company.name.toLowerCase().includes(word))
      )
      .sort((a, b) => a.name.localeCompare(b.name))
      .slice(0, 3)

    // nameで3件以下ならopenUrl, enName, kanaでfilter
    let additionalCompanies = []
    if (nameFilteredCompanies.length < 3) {
      additionalCompanies = state.suggestData.companies
        .filter((company) => {
          return searchWords.every(
            (word) =>
              !nameFilteredCompanies.includes(company) && // すでに取得済みのものは除外
              (company.openUrl.toLowerCase().includes(word) ||
                company.enName?.toLowerCase().includes(word) ||
                company.kana?.toLowerCase().includes(word))
          )
        })
        .sort((a, b) => a.name.localeCompare(b.name))
        .slice(0, 3 - nameFilteredCompanies.length) // 残りの件数を補足
    }

    const companies = [...nameFilteredCompanies, ...additionalCompanies]

    // カテゴリfilter
    const categories = state.suggestData.tags
      .filter((tag) =>
        searchWords.every((word) => tag.name.toLowerCase().includes(word))
      )
      .sort((a, b) => a.name.localeCompare(b.name))
      .slice(0, 3)

    return { companies, categories }
  }
}

export const mutations = {
  SET_SEARCH_CONTENTS_LIST(state, payload) {
    state.searchTotalCount = payload.res.pageMeta.totalCount
    payload.add
      ? payload.res.result.contents.map((x: any) =>
          state.searchResults.contents.push(x)
        )
      : (state.searchResults = payload.res.result)
  },
  REMOVE_RESULTS_LIST(state) {
    state.searchResults = []
  },
  SET_NO_RESULTS_FLAG(state, bool: boolean) {
    state.noSearchResult = bool
    if (bool) state.searchTotalCount = 0
  },
  SET_LOADING_FLAG(state, bool: boolean) {
    state.showLoading = bool
  },
  SET_NO_QUERY(state, bool: boolean) {
    state.noQuery = bool
  },
  SET_OPEN_PANELS(state, panels) {
    state.openPanels = panels
  },
  SET_SUGGEST_DATA(state, data) {
    state.suggestData = data
  }
}

export const actions = {
  async getSearchResults({ commit }, { url, context, add = false }) {
    // queryパラメータを解析するためURLオブジェクトを作成
    const urlObject = new URL(url)
    // queryを取得
    const queryParam = urlObject.searchParams.get('query')

    if (!add) await commit('SET_LOADING_FLAG', true) // loadingSpinerの表示制御
    if (queryParam) {
      commit('SET_NO_QUERY', false)
      const config = {
        auth: authSetting,
        withCredentials: true
      }
      await this.$axios
        .$get(url, config)
        .then((res: any) => {
          // 検索結果がない場合は[],ある場合は{}が返る
          if (Array.isArray(res)) {
            // 検索結果がない場合はFlagをtrue
            commit('SET_NO_RESULTS_FLAG', true)
            commit('REMOVE_RESULTS_LIST')
            commit('SET_LOADING_FLAG', false) // loadingSpinerの表示制御
          } else {
            commit('SET_SEARCH_CONTENTS_LIST', {
              res: camelcaseKeys(res, { deep: true }),
              add
            })
            commit('SET_NO_RESULTS_FLAG', false)
          }
        })
        .then(() => {
          if (!add) commit('SET_LOADING_FLAG', false)
        })
        .catch((err) => {
          err.response.status === 400 ? error400() : errorHandler(err, context)
          return err
          function error400() {
            commit('SET_LOADING_FLAG', false)
            commit('SET_NO_RESULTS_FLAG', true)
          }
        })
    } else {
      commit('SET_NO_QUERY', true)
      commit('SET_LOADING_FLAG', false)
    }
  },
  setOpenPanels({ commit }, panel: any) {
    commit('SET_OPEN_PANELS', panel)
  },
  async getSuggestData({ state, commit }) {
    if (state.suggestData) return
    try {
      const url = process.env.API_URL + `/suggest`
      const config = {
        auth: authSetting
      }
      const data = await this.$axios.$get(url, config)
      commit('SET_SUGGEST_DATA', camelcaseKeys(data, { deep: true }))
    } catch (error) {
      return error
    }
  }
}
