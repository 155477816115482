import camelcaseKeys from 'camelcase-keys'
import authSetting from '~/config/authSetting'

export const state = () => ({
  showLoading: false,
  noResults: false,
  articleRecods: [],
  articlesPageMeta: [],
  knowhowIdArticleContent: {},
  knowhowIdArticleKeywords: ''
})

export const mutations = {
  SET_KNOWHOWS(state, article) {
    state.articleRecods = article
    state.showLoading = true
  },
  SET_PAGE_META(state, pageMeta) {
    state.articlesPageMeta = pageMeta
  },
  CHANGE_SHOW_LOADING(state, showBoolean) {
    state.showLoading = showBoolean
  },
  SET_CATEGORY_FILTER(state, result) {
    if (result.records) {
      state.articleRecods = result.records
      state.articlesPageMeta = result.pageMeta
    } else {
      state.articleRecods = result
      state.articlesPageMeta = result
    }
  },
  SET_NO_RESULTS(state, showBoolean: boolean) {
    state.noResults = showBoolean
  },
  SET_ARTICLE_CONTENT(state, res: any) {
    state.knowhowIdArticleContent = res
    state.knowhowIdArticleKeywords = res.tagArray.join(',')
  }
}

export const actions = {
  getKnowhowIdArticleContent(context, res: any) {
    context.commit('SET_ARTICLE_CONTENT', res)
    // store/bookshelf.tsのmutationにcommit
    context.commit('bookshelf/SET_IS_SAVED', res.isSaved, { root: true })
    context.commit('bookshelf/SET_COMPLITE_IS_SAVED', true, { root: true })
  },
  async getKnowhows({ commit }, payload: any) {
    commit('CHANGE_SHOW_LOADING', true)
    const config = {
      auth: authSetting,
      withCredentials: true
    }
    const categoryIdString = payload.route.query.category
    const categoryIdArray = categoryIdString ? categoryIdString.split(',') : []
    function categoryIdsStr(str) {
      return categoryIdArray.length
        ? categoryIdArray
            .map((v: any, i: number) =>
              !i ? str + `category_ids[]=` + v : `&category_ids[]=` + v
            )
            .join('')
        : ''
    }
    const page = payload.route.query.page
      ? `?page=${payload.route.query.page}`
      : ''
    const baseKnowhowsUrl = process.env.API_URL + '/knowhows'
    const knowhowsUrl = page
      ? baseKnowhowsUrl + page + categoryIdsStr('&')
      : baseKnowhowsUrl + categoryIdsStr('?')
    const followUrl = page
      ? baseKnowhowsUrl + page + '&is_following=true' + categoryIdsStr('&')
      : baseKnowhowsUrl + '?is_following=true' + categoryIdsStr('&')

    if (payload.route.query.filter === 'follow') {
      await this.$axios
        .$get(followUrl, config)
        .then((res) => {
          commit('SET_KNOWHOWS', camelcaseKeys(res.records, { deep: true }))
          commit('SET_PAGE_META', camelcaseKeys(res.page_meta, { deep: true }))
          commit('SET_NO_RESULTS', false)
          commit('CHANGE_SHOW_LOADING', false)
        })
        .catch((err) => {
          commit('SET_NO_RESULTS', true)
          commit('CHANGE_SHOW_LOADING', false)
          return err
        })
    } else {
      await this.$axios
        .$get(knowhowsUrl, config)
        .then((res) => {
          commit('SET_KNOWHOWS', camelcaseKeys(res.records, { deep: true }))
          commit('SET_PAGE_META', camelcaseKeys(res.page_meta, { deep: true }))
          commit('SET_NO_RESULTS', false)
          commit('CHANGE_SHOW_LOADING', false)
        })
        .catch((err) => {
          commit('SET_NO_RESULTS', true)
          commit('CHANGE_SHOW_LOADING', false)
          return err
        })
    }
  }
}
