
import { Component, Vue } from 'vue-property-decorator'
import Logo from '~/components/shared/Logo.vue'

@Component({
  components: {
    Logo
  }
})
export default class Footer extends Vue {
  private links = require('~/assets/json/links.json')
}
