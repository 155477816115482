import camelcaseKeys from 'camelcase-keys'

export const state = () => ({
  movies: []
})

export const mutations = {
  SET_MOVIE(state, movie) {
    state.movies.push(movie)
    state.movies.sort((a: any, b: any) => a.order - b.order)
  },
  CLEAR_MOVIES(state) {
    state.movies = []
  }
}

export const actions = {
  async getMovie({ state, commit }, movie) {
    const registeredId = state.movies.some((v) => v.id === movie.id)
    if (!registeredId && !movie.movieType) {
      movie.thumbnail = `https://img.youtube.com/vi/${movie.movieId}/hqdefault.jpg`
      movie.params
        ? (movie.iframeUrl = `https://www.youtube.com/embed/${movie.movieId}${movie.params}&autoplay=1`)
        : (movie.iframeUrl = `https://www.youtube.com/embed/${movie.movieId}?autoplay=1`)
      commit('SET_MOVIE', movie)
    } else if (!registeredId && movie.movieType) {
      const vimeoUrl = `https://vimeo.com/api/oembed.json?url=https%3A//vimeo.com/${movie.movieId}`
      await this.$axios
        .$get(vimeoUrl)
        .then((res: any) => {
          movie.thumbnail = res.thumbnail_url
          if (movie.params) {
            movie.params[0] !== '#'
              ? (movie.iframeUrl = `https://player.vimeo.com/video/${
                  movie.movieId
                }?autoplay=1${'&' + movie.params.slice(1)}`)
              : (movie.iframeUrl = `https://player.vimeo.com/video/${movie.movieId}?autoplay=1${movie.params}`)
          } else {
            movie.iframeUrl = `https://player.vimeo.com/video/${movie.movieId}?autoplay=1`
          }
        })
        .catch((err) => err)
      commit('SET_MOVIE', camelcaseKeys(movie, { deep: true }))
    }
  },
  clearMovies({ commit }) {
    commit('CLEAR_MOVIES')
  }
}
