import { Component, Vue } from 'vue-property-decorator'

@Component
export default class MixinMemberId extends Vue {
  memberId!: number | null

  beforeCreate() {
    if (process.client) {
      const member = sessionStorage.getItem('member')
      if (member) {
        // このMixinが実行されるときには、ログイン済みのuserはsessionStorageにmember_idが保持されている
        // plugins側でmember_idの確認＆再取得を実行
        const parseData = JSON.parse(member)
        this.memberId = parseData.id
      }
    } else {
      this.memberId = null
    }
  }
}
