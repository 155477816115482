export const state = () => ({
  spMenuActive: false
})

export const mutations = {
  SET_MENU_ACTIVE(state, menuState: boolean) {
    state.spMenuActive = menuState
  }
}

export const actions = {
  setMenuActive({ commit }, menuState: boolean) {
    commit('SET_MENU_ACTIVE', !menuState)
  },
  setMenuRoteChange({ commit }) {
    commit('SET_MENU_ACTIVE', false)
  }
}
