import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _31c939c1 = () => interopDefault(import('../pages/agreement.vue' /* webpackChunkName: "pages/agreement" */))
const _295c1f38 = () => interopDefault(import('../pages/categories/index.vue' /* webpackChunkName: "pages/categories/index" */))
const _67c96a15 = () => interopDefault(import('../pages/companies/index.vue' /* webpackChunkName: "pages/companies/index" */))
const _7fa84a14 = () => interopDefault(import('../pages/contents/index.vue' /* webpackChunkName: "pages/contents/index" */))
const _3b53d00d = () => interopDefault(import('../pages/faq.vue' /* webpackChunkName: "pages/faq" */))
const _1f53c930 = () => interopDefault(import('../pages/feature/index.vue' /* webpackChunkName: "pages/feature/index" */))
const _32a4d3a4 = () => interopDefault(import('../pages/forgot_password.vue' /* webpackChunkName: "pages/forgot_password" */))
const _30a9f720 = () => interopDefault(import('../pages/login.vue' /* webpackChunkName: "pages/login" */))
const _12bd5f49 = () => interopDefault(import('../pages/oauth-error.vue' /* webpackChunkName: "pages/oauth-error" */))
const _18946d87 = () => interopDefault(import('../pages/privacy_inquiry.vue' /* webpackChunkName: "pages/privacy_inquiry" */))
const _0a076ee9 = () => interopDefault(import('../pages/recommended_stories/index.vue' /* webpackChunkName: "pages/recommended_stories/index" */))
const _24fae844 = () => interopDefault(import('../pages/search/index.vue' /* webpackChunkName: "pages/search/index" */))
const _30eed9f1 = () => interopDefault(import('../pages/signup.vue' /* webpackChunkName: "pages/signup" */))
const _181402aa = () => interopDefault(import('../pages/talentbook_guide.vue' /* webpackChunkName: "pages/talentbook_guide" */))
const _0949cb47 = () => interopDefault(import('../pages/members/additional_info.vue' /* webpackChunkName: "pages/members/additional_info" */))
const _5a43d4a5 = () => interopDefault(import('../pages/members/bookshelf/index.vue' /* webpackChunkName: "pages/members/bookshelf/index" */))
const _331ea443 = () => interopDefault(import('../pages/members/follow/index.vue' /* webpackChunkName: "pages/members/follow/index" */))
const _6fa1ced8 = () => interopDefault(import('../pages/members/history/index.vue' /* webpackChunkName: "pages/members/history/index" */))
const _72e1fe4d = () => interopDefault(import('../pages/members/mypage/index.vue' /* webpackChunkName: "pages/members/mypage/index" */))
const _e86102ac = () => interopDefault(import('../pages/members/profile.vue' /* webpackChunkName: "pages/members/profile" */))
const _795282c0 = () => interopDefault(import('../pages/members/recommend/index.vue' /* webpackChunkName: "pages/members/recommend/index" */))
const _97e0340c = () => interopDefault(import('../pages/members/settings/change_email.vue' /* webpackChunkName: "pages/members/settings/change_email" */))
const _3523742d = () => interopDefault(import('../pages/members/settings/change_password.vue' /* webpackChunkName: "pages/members/settings/change_password" */))
const _cbc34408 = () => interopDefault(import('../pages/members/settings/quit.vue' /* webpackChunkName: "pages/members/settings/quit" */))
const _67025409 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _361fd0ed = () => interopDefault(import('../pages/members/activate/_id.vue' /* webpackChunkName: "pages/members/activate/_id" */))
const _ea03ba50 = () => interopDefault(import('../pages/members/reset_email/_id.vue' /* webpackChunkName: "pages/members/reset_email/_id" */))
const _8a17d376 = () => interopDefault(import('../pages/members/reset_password/_id.vue' /* webpackChunkName: "pages/members/reset_password/_id" */))
const _0d823794 = () => interopDefault(import('../pages/preview/companies/_id.vue' /* webpackChunkName: "pages/preview/companies/_id" */))
const _161d5d4e = () => interopDefault(import('../pages/preview/knowhows/_id.vue' /* webpackChunkName: "pages/preview/knowhows/_id" */))
const _332ad3cc = () => interopDefault(import('../pages/preview/reports/_id.vue' /* webpackChunkName: "pages/preview/reports/_id" */))
const _187ed0ae = () => interopDefault(import('../pages/preview/stories/_id.vue' /* webpackChunkName: "pages/preview/stories/_id" */))
const _254ee0c0 = () => interopDefault(import('../pages/categories/_id.vue' /* webpackChunkName: "pages/categories/_id" */))
const _707667ca = () => interopDefault(import('../pages/feature/_id/index.vue' /* webpackChunkName: "pages/feature/_id/index" */))
const _64d640f2 = () => interopDefault(import('../pages/info/_id.vue' /* webpackChunkName: "pages/info/_id" */))
const _aeb68456 = () => interopDefault(import('../pages/_companyId/index.vue' /* webpackChunkName: "pages/_companyId/index" */))
const _be1f4720 = () => interopDefault(import('../pages/_companyId/books/index.vue' /* webpackChunkName: "pages/_companyId/books/index" */))
const _6cf059be = () => interopDefault(import('../pages/_companyId/knowhows/index.vue' /* webpackChunkName: "pages/_companyId/knowhows/index" */))
const _38ca2dac = () => interopDefault(import('../pages/_companyId/media/index.vue' /* webpackChunkName: "pages/_companyId/media/index" */))
const _1ee45cd3 = () => interopDefault(import('../pages/_companyId/movies/index.vue' /* webpackChunkName: "pages/_companyId/movies/index" */))
const _744edfd0 = () => interopDefault(import('../pages/_companyId/press_releases/index.vue' /* webpackChunkName: "pages/_companyId/press_releases/index" */))
const _406387ce = () => interopDefault(import('../pages/_companyId/stories/index.vue' /* webpackChunkName: "pages/_companyId/stories/index" */))
const _78cabad8 = () => interopDefault(import('../pages/_companyId/books/_id.vue' /* webpackChunkName: "pages/_companyId/books/_id" */))
const _e0b498b4 = () => interopDefault(import('../pages/_companyId/knowhows/_id.vue' /* webpackChunkName: "pages/_companyId/knowhows/_id" */))
const _18ab93a6 = () => interopDefault(import('../pages/_companyId/reports/_id.vue' /* webpackChunkName: "pages/_companyId/reports/_id" */))
const _25be70c1 = () => interopDefault(import('../pages/_companyId/stories/_id.vue' /* webpackChunkName: "pages/_companyId/stories/_id" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/agreement",
    component: _31c939c1,
    name: "agreement"
  }, {
    path: "/categories",
    component: _295c1f38,
    name: "categories"
  }, {
    path: "/companies",
    component: _67c96a15,
    name: "companies"
  }, {
    path: "/contents",
    component: _7fa84a14,
    name: "contents"
  }, {
    path: "/faq",
    component: _3b53d00d,
    name: "faq"
  }, {
    path: "/feature",
    component: _1f53c930,
    name: "feature"
  }, {
    path: "/forgot_password",
    component: _32a4d3a4,
    name: "forgot_password"
  }, {
    path: "/login",
    component: _30a9f720,
    name: "login"
  }, {
    path: "/oauth-error",
    component: _12bd5f49,
    name: "oauth-error"
  }, {
    path: "/privacy_inquiry",
    component: _18946d87,
    name: "privacy_inquiry"
  }, {
    path: "/recommended_stories",
    component: _0a076ee9,
    name: "recommended_stories"
  }, {
    path: "/search",
    component: _24fae844,
    name: "search"
  }, {
    path: "/signup",
    component: _30eed9f1,
    name: "signup"
  }, {
    path: "/talentbook_guide",
    component: _181402aa,
    name: "talentbook_guide"
  }, {
    path: "/members/additional_info",
    component: _0949cb47,
    name: "members-additional_info"
  }, {
    path: "/members/bookshelf",
    component: _5a43d4a5,
    name: "members-bookshelf"
  }, {
    path: "/members/follow",
    component: _331ea443,
    name: "members-follow"
  }, {
    path: "/members/history",
    component: _6fa1ced8,
    name: "members-history"
  }, {
    path: "/members/mypage",
    component: _72e1fe4d,
    name: "members-mypage"
  }, {
    path: "/members/profile",
    component: _e86102ac,
    name: "members-profile"
  }, {
    path: "/members/recommend",
    component: _795282c0,
    name: "members-recommend"
  }, {
    path: "/members/settings/change_email",
    component: _97e0340c,
    name: "members-settings-change_email"
  }, {
    path: "/members/settings/change_password",
    component: _3523742d,
    name: "members-settings-change_password"
  }, {
    path: "/members/settings/quit",
    component: _cbc34408,
    name: "members-settings-quit"
  }, {
    path: "/",
    component: _67025409,
    name: "index"
  }, {
    path: "/members/activate/:id?",
    component: _361fd0ed,
    name: "members-activate-id"
  }, {
    path: "/members/reset_email/:id?",
    component: _ea03ba50,
    name: "members-reset_email-id"
  }, {
    path: "/members/reset_password/:id?",
    component: _8a17d376,
    name: "members-reset_password-id"
  }, {
    path: "/preview/companies/:id?",
    component: _0d823794,
    name: "preview-companies-id"
  }, {
    path: "/preview/knowhows/:id?",
    component: _161d5d4e,
    name: "preview-knowhows-id"
  }, {
    path: "/preview/reports/:id?",
    component: _332ad3cc,
    name: "preview-reports-id"
  }, {
    path: "/preview/stories/:id?",
    component: _187ed0ae,
    name: "preview-stories-id"
  }, {
    path: "/categories/:id",
    component: _254ee0c0,
    name: "categories-id"
  }, {
    path: "/feature/:id",
    component: _707667ca,
    name: "feature-id"
  }, {
    path: "/info/:id?",
    component: _64d640f2,
    name: "info-id"
  }, {
    path: "/:companyId",
    component: _aeb68456,
    name: "companyId"
  }, {
    path: "/:companyId/books",
    component: _be1f4720,
    name: "companyId-books"
  }, {
    path: "/:companyId/knowhows",
    component: _6cf059be,
    name: "companyId-knowhows"
  }, {
    path: "/:companyId/media",
    component: _38ca2dac,
    name: "companyId-media"
  }, {
    path: "/:companyId/movies",
    component: _1ee45cd3,
    name: "companyId-movies"
  }, {
    path: "/:companyId/press_releases",
    component: _744edfd0,
    name: "companyId-press_releases"
  }, {
    path: "/:companyId/stories",
    component: _406387ce,
    name: "companyId-stories"
  }, {
    path: "/:companyId/books/:id",
    component: _78cabad8,
    name: "companyId-books-id"
  }, {
    path: "/:companyId/knowhows/:id",
    component: _e0b498b4,
    name: "companyId-knowhows-id"
  }, {
    path: "/:companyId/reports/:id?",
    component: _18ab93a6,
    name: "companyId-reports-id"
  }, {
    path: "/:companyId/stories/:id",
    component: _25be70c1,
    name: "companyId-stories-id"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
