export default function ({ route, redirect }) {
  // クライアントサイドでなければreturn
  if (!process.client) return

  const beforePath = localStorage.getItem('beforePath')
  const mypagePath = '/members/mypage'
  const twitterLogin = JSON.parse(
    localStorage.getItem('twitterLogin') || 'false'
  )

  // beforePath がない場合はreturn
  if (!beforePath) return

  // Twitter loginをキャンセルした場合、元のページにリダイレクトさせるため個別にflag管理
  if (twitterLogin) {
    localStorage.removeItem('twitterLogin')
    localStorage.removeItem('beforePath')
    return redirect(beforePath)
  }

  // 通常のリダイレクト処理
  if (route.path === mypagePath) {
    localStorage.removeItem('beforePath')
    return redirect(beforePath)
  }
}
