import Cookies from 'universal-cookie'
import camelcaseKeys from 'camelcase-keys'
import authSetting from '~/config/authSetting'
import { API } from '~/types/api'
import { Infomation } from '~/types/infomation'

export const state = () => ({
  infomation: {}
})

export const mutations = {
  SET_INFOMATION(state, res: Infomation.InfoData) {
    state.infomation = res
  }
}

export const actions = {
  async getInfomation({ commit }, payload: any) {
    const cookies = new Cookies()
    const url = process.env.API_URL + `/info/${payload.infoId}`
    const config = {
      auth: authSetting,
      withCredentials: true
    }
    const res = await this.$axios.$get(url, config).catch((err) => {
      // APIレスポンスが401だった場合
      const unauthorized =
        err.response.status === 401 || err.response.status === 404
      if (unauthorized) {
        cookies.set('unauthorized_error', true)
        logout()
      }
    })
    commit('SET_INFOMATION', camelcaseKeys(res, { deep: true }))
    async function logout() {
      const csrfUrl = process.env.API_URL + '/members/get_csrf_token'
      try {
        await this.$axios
          .$get(csrfUrl, {
            auth: authSetting,
            withCredentials: true
          })
          .then((token: API.CSRF) => {
            logoutAction(token.csrf_token)
          })
      } catch (e) {
        return e
      }
    }

    async function logoutAction(csrf: string) {
      const url = process.env.API_URL + '/members/logout'
      const config = {
        headers: {
          'X-CSRF-Token': csrf
        },
        auth: authSetting,
        withCredentials: true
      }
      try {
        await this.$axios
          .$patch(url, {}, config)
          .then(() => {
            // sessionStorageのmemberId削除
            sessionStorage.removeItem('member')
          })
          .then(() => {
            const redirectUrl = `/`
            location.href = redirectUrl
          })
      } catch (e) {
        return e
      }
    }
  }
}
