import { API } from '~/types/api'

export const state = () => ({
  errorCode: null,
  currentState: false,
  passwordResettingProfile: {}
})

export const mutations = {
  SET_ERROR_CODE(state, code: number) {
    state.errorCode = code
  },
  SET_ACTIVATE(state, bool: boolean) {
    state.currentState = bool
  },
  SET_PASSWORD_RESETTING_ACTIVATE(state, payload: API.ResetPassword) {
    state.passwordResettingProfile = payload
  }
}

export const actions = {
  updateErrorCode({ commit }, code: number) {
    commit('SET_ERROR_CODE', code)
  },
  updateActivate({ commit }, bool: boolean) {
    commit('SET_ACTIVATE', bool)
  },
  updatePasswordResettingActivate({ commit }, payload: API.ResetPassword) {
    commit('SET_PASSWORD_RESETTING_ACTIVATE', payload)
  }
}
