export default async ({ app, store }) => {
  await app.router.afterEach((to, from) => {
    if (
      (to.page !== 'stories' && from.page !== 'stories') ||
      (to.page !== 'knowhows' && from.page !== 'knowhows')
    ) {
      store.dispatch('tags/removeSelected')
    }
  })
}
