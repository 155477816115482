import UniversalCookie from 'universal-cookie'
import authSetting from '../../config/authSetting'

export default {
  methods: {
    async logoutTest(isHeader) {
      const csrfUrl = process.env.API_URL + '/members/get_csrf_token'
      try {
        await this.$axios
          .$get(csrfUrl, {
            auth: authSetting,
            withCredentials: true
          })
          .then((token: any) => {
            this.logoutAction({ token: token.csrf_token, loggedout: isHeader })
          })
      } catch (e) {
        return e
      }
    },
    async logoutAction(payload) {
      const cookies = new UniversalCookie()
      const url = process.env.API_URL + '/members/logout'
      const config = {
        headers: {
          'X-CSRF-Token': payload.token
        },
        auth: authSetting,
        withCredentials: true
      }
      try {
        await this.$axios
          .$patch(url, {}, config)
          .then(() => {
            // API401の際にsetされるcookie
            if (payload.loggedout) {
              sessionStorage.setItem('logout', 'header')
              cookies.remove('unauthorized_error')
              cookies.remove('member_id')
            } else {
              sessionStorage.setItem('logout', 'error')
              cookies.set('unauthorized_error', true)
              cookies.remove('member_id')
            }
            // sessionStorageのmemberId削除
            sessionStorage.removeItem('member')
          })
          .then(() => {
            const redirectUrl = `/login?logout=true`
            payload.loggedout
              ? (location.href = redirectUrl)
              : (location.href = '/')
          })
      } catch (e) {
        return e
      }
    }
  }
}
