
import { Component, Prop, Vue } from 'vue-property-decorator'

import BackHomeIcon from '../components/shared/BackHomeIcon.vue'
import Error500 from '~/components/layouts/error/Error500.vue'
import Header from '~/components/layouts/Header.vue'
import Footer from '~/components/layouts/Footer.vue'

@Component({
  components: {
    BackHomeIcon,
    Header,
    Footer,
    Error500
  },
  layout: 'none'
})
export default class error extends Vue {
  @Prop({
    type: Object,
    required: true
  })
  private error!: any
}
