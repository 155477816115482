import Cookies from 'universal-cookie'
import camelcaseKeys from 'camelcase-keys'
import authSetting from '~/config/authSetting'
import { errorHandler } from '~/lib/errorHandling'
import { Follow } from '~/types/follow'
import { API } from '~/types/api'

export const state = () => ({
  currentCompany: {}, // 現在閲覧中(記事や_companyIdのページ)の企業情報
  followingCompany: false, // follow.vue(単一follow表示component)で参照 現在表示中の企業がfollowされているどうか
  compliteFollowing: false, // 記事(単一follow表示component)で読み込み完了時にtrue/falseが切り替わってしまうためflag管理
  topCompanies: [], // topに表示するcompaniesのlist、followもここを参照
  companyBookIdArticles: {}, // _companyId/booksのlist
  companyIdStoryArticles: {}, // _companyId/storiesのlist
  companyIdKnowhowArticles: {}, // _companyId/storiesのlist
  showLoading: true, // companySearchのloadingSpiner
  noSearchResult: false, // companySearchの検索結果(lengthで表示制御するとタイミング合わないためflag管理)
  searchResults: [],
  searchTotalCount: 0
})

export const mutations = {
  SET_CURRENT_COMPANY(state, company) {
    state.currentCompany = company
    state.followingCompany = company.following
  },
  SET_CLEAR_CURRENT_CUMPANY(state) {
    state.currentCompany = {}
  },
  SET_FOLLOWING(state, payload) {
    state.followingCompany = payload.following
  },
  SET_COMPLITE_FOLLOWING(state, res) {
    state.compliteFollowing = res
  },
  SET_TOP_COMPANIES(state, companies) {
    const updatedCompanies = companies.map((v: any) => ({
      ...v,
      following: Object.prototype.hasOwnProperty.call(v, 'following')
        ? v.following
        : false
    }))
    state.topCompanies = updatedCompanies
  },
  SET_UPDATE_TOP_COMPANY_FOLLOWING(state, companyId) {
    state.topCompanies.map((v: any) => {
      if (v.id === companyId) v.following = !v.following
    })
  },
  SET_SEARCH_COMPANIES_LIST(state, payload) {
    const records = payload.res.records
    const count = payload.res.pageMeta.totalCount
    state.searchTotalCount = count
    payload.add
      ? records.map((x: any) => state.searchResults.push(x))
      : (state.searchResults = records)
  },
  SET_NO_RESULTS_FLAG(state, bool: boolean) {
    state.noSearchResult = bool
    if (bool) state.searchTotalCount = 0
  },
  REMOVE_COMPANIES_LIST(state) {
    state.searchResults = []
  },
  SET_COMPANY_BOOKID(state, res) {
    state.companyBookIdArticles = res
  },
  SET_COMPSNYID_ARTICLES(state, res) {
    res.records[0].storyTypeJa === 'ストーリー'
      ? (state.companyIdStoryArticles = res)
      : (state.companyIdKnowhowArticles = res)
  },
  SET_SEARCH_FLAG(state, bool: boolean) {
    state.showLoading = bool
  }
}

export const actions = {
  async getPreviewCompany({ commit }, params: any) {
    const url = process.env.API_URL + `/preview/companies/${params.id}`
    const config = {
      auth: authSetting,
      withCredentials: true
    }
    const company = await this.$axios.$get(url, config)
    commit('SET_CURRENT_COMPANY', camelcaseKeys(company, { deep: true }))
    commit('SET_COMPLITE_FOLLOWING', true)
  },
  async getCompany({ state, commit }, params: any) {
    const companyId = params.companyId
    const config = {
      auth: authSetting,
      withCredentials: true
    }
    if (!companyId) {
      commit('SET_CLEAR_CURRENT_CUMPANY')
    } else if (state.currentCompany.id !== companyId) {
      const url = process.env.API_URL + `/companies/${companyId}`
      const company = await this.$axios.$get(url, config)
      company.recommendedStories = company.stories.slice(0, 3)
      company.books.sort((a, b) => a.order_id - b.order_id)
      processAfterAPI(company)
    }
    function processAfterAPI(company: any) {
      commit('SET_CURRENT_COMPANY', camelcaseKeys(company, { deep: true }))
      commit('SET_COMPLITE_FOLLOWING', true)
    }
  },
  getCompanyBookId({ commit }, res: any) {
    commit('SET_COMPANY_BOOKID', res)
  },
  getCompanyIdArticles({ commit }, res: any) {
    commit('SET_COMPSNYID_ARTICLES', res)
  },
  changeFollowingStatus({ commit }, payload: Follow.ChangeFollow) {
    commit('SET_FOLLOWING', payload)
    commit('SET_COMPLITE_FOLLOWING', true)
  },
  backToDefaultFollowingFlag({ commit }, stateBool: boolean) {
    commit('SET_COMPLITE_FOLLOWING', stateBool)
  },
  getTopCompanies({ commit }, companies) {
    commit('SET_TOP_COMPANIES', companies)
  },
  updateTopCompanyFollowing({ commit }, companyId) {
    commit('SET_UPDATE_TOP_COMPANY_FOLLOWING', companyId)
  },
  // ▼ 古いaction疑惑
  async updateCompaniesFollowing({ commit }, payload: Follow.UpdateCompany) {
    const csrfUrl = process.env.API_URL + '/members/get_csrf_token'
    const url = process.env.API_URL + `/members/${payload.memberId}/follows`
    const cookies = new Cookies()
    // stateを変更する前の状態なので!payload.followで変更したいvalueにする
    if (!payload.follow) {
      try {
        await this.$axios
          .$get(csrfUrl, {
            auth: authSetting,
            withCredentials: true
          })
          .then((token: API.CSRF) => {
            const postData = {
              company_id: payload.id
            }
            const config = {
              headers: {
                'X-CSRF-Token': token.csrf_token
              },
              auth: authSetting,
              withCredentials: true
            }
            try {
              this.$axios
                .$post(url, postData, config)
                .then(() => {
                  commit('CHANGE_ALL_COMPANY_FOLLOWING', payload)
                })
                .catch((err) => {
                  // APIレスポンスが401だった場合
                  const unauthorized =
                    err.response.status === 401 || err.response.status === 404
                  if (unauthorized) {
                    cookies.set('unauthorized_error', true)
                    logout()
                  }
                })
            } catch (err) {
              return err
            }
          })
      } catch (e) {
        return e
      }
    } else {
      try {
        await this.$axios
          .$get(csrfUrl, {
            auth: authSetting,
            withCredentials: true
          })
          .then((token: API.CSRF) => {
            const deleteData = {
              company_id: payload.id
            }
            const config = {
              headers: {
                'X-CSRF-Token': token.csrf_token
              },
              data: deleteData,
              auth: authSetting,
              withCredentials: true
            }
            try {
              this.$axios
                .$delete(url, config)
                .then(() => {
                  commit('CHANGE_ALL_COMPANY_FOLLOWING', payload)
                })
                .catch((err) => {
                  // APIレスポンスが401だった場合
                  const unauthorized =
                    err.response.status === 401 || err.response.status === 404
                  if (unauthorized) {
                    cookies.set('unauthorized_error', true)
                    logout()
                  }
                })
            } catch (err) {
              return err
            }
          })
      } catch (e) {
        return e
      }
    }
    async function logout() {
      const csrfUrl = process.env.API_URL + '/members/get_csrf_token'
      try {
        await this.$axios
          .$get(csrfUrl, {
            auth: authSetting,
            withCredentials: true
          })
          .then((token: API.CSRF) => {
            logoutAction(token.csrf_token)
          })
      } catch (e) {
        return e
      }
    }

    async function logoutAction(csrf: string) {
      const url = process.env.API_URL + '/members/logout'
      const config = {
        headers: {
          'X-CSRF-Token': csrf
        },
        auth: authSetting,
        withCredentials: true
      }
      try {
        await this.$axios
          .$patch(url, {}, config)
          .then(() => {
            // sessionStorageのmemberId削除
            sessionStorage.removeItem('member')
          })
          .then(() => {
            const redirectUrl = `/`
            location.href = redirectUrl
          })
      } catch (e) {
        return e
      }
    }
  },
  // ▲ 古いaction疑惑
  // payloadのaddはdefaultでfalse,mutationでpushする場合のみtrue
  async getSearchResults({ commit }, { url, context, add = false }) {
    if (!add) commit('SET_SEARCH_FLAG', true) // loadingSpinerの表示制御
    const config = {
      auth: authSetting,
      withCredentials: true
    }
    await this.$axios
      .$get(url, config)
      .then((res: any) => {
        // 検索結果がない場合は[],ある場合は{}が返る
        if (Array.isArray(res)) {
          // 検索結果がない場合はFlagをtrue
          commit('SET_NO_RESULTS_FLAG', true)
          commit('REMOVE_COMPANIES_LIST')
        } else {
          commit('SET_SEARCH_COMPANIES_LIST', {
            res: camelcaseKeys(res, { deep: true }),
            add
          })
          commit('SET_NO_RESULTS_FLAG', false)
        }
      })
      .then(() => {
        if (!add) commit('SET_SEARCH_FLAG', false) // loadingSpinerの表示制御
      })
      .catch((err) => {
        errorHandler(err, context)
        return err
      })
  }
}
