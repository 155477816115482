export const state = () => ({
  loginMessage: false,
  unauthorizedMemberMessage: false,
  membersHeader: false,
  memberIdAPIFlag: false
})

export const mutations = {
  SET_LOGIN_MESSAGE(state, afterLogin: boolean) {
    state.loginMessage = afterLogin
  },
  SET_UNAUTHORIZED_MEMBER_MESSAGE(state, bool: boolean) {
    state.unauthorizedMemberMessage = bool
  },
  SET_MEMBERS_HEADER(state, bool: boolean) {
    state.membersHeader = bool
  },
  SET_MEMBER_ID_API_FLAG(state, bool: boolean) {
    state.memberIdAPIFlag = bool
  }
}

export const actions = {
  updateLoginMessage({ commit }, afterLogin: boolean) {
    commit('SET_LOGIN_MESSAGE', afterLogin)
  },
  updateUnauthorizedMember({ commit }, bool: boolean) {
    commit('SET_UNAUTHORIZED_MEMBER_MESSAGE', bool)
  },
  updateMembersHeader({ commit }, bool: boolean) {
    commit('SET_MEMBERS_HEADER', bool)
  },
  getMemberIdAPIFlag({ commit }, bool: boolean) {
    commit('SET_MEMBER_ID_API_FLAG', bool)
  }
}
