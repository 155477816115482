
import { Component, Vue } from 'vue-property-decorator'
import Header from '~/components/layouts/Header.vue'
import Footer from '~/components/layouts/Footer.vue'
import NonMemberModal from '~/components/shared/NonMemberModal.vue'
import Modal from '~/components/shared/Modal.vue'
import LogoBtn from '~/components/shared/LogoBtn.vue'
import Logo from '~/components/shared/Logo.vue'
import MessageAfterLogin from '~/components/shared/MessageAfterLogin.vue'
import MixinMemberId from '~/components/mixins/MixinMemberId'

@Component({
  components: {
    Header,
    Footer,
    NonMemberModal,
    Modal,
    LogoBtn,
    Logo,
    MessageAfterLogin
  },
  middleware: 'redirectAfterLogin',
  mixins: [MixinMemberId]
})
export default class DefaultLayout extends Vue {
  private memberId!: number

  private head() {
    return {
      htmlAttrs: {
        class: ['reset-overflow']
      }
    }
  }

  private mounted() {
    document.documentElement.style.setProperty(
      '--vh',
      `${window.innerHeight * 0.01}px`
    )

    const currentPathName = this.$route.name
    // removeDtorageの除外ページ
    const excludeNames = ['login', 'members-activate-id']
    const twitterLogin = JSON.parse(
      localStorage.getItem('twitterLogin') || 'false'
    )

    // 現在のパスが excludeNames 以外の場合にのみ removeItem を実行
    if (this.getStorage && !excludeNames.includes(currentPathName)) {
      if (twitterLogin) {
        this.removeStorage('twitterLogin')
        this.$router.push(this.getStorage)
      }
      this.removeStorage('beforePath')
      this.removeStorage('twitterLogin')
    }
  }

  private isPreviewPath(): boolean {
    return /^\/preview\//.test(this.$route.path)
  }

  private modalClose(): void {
    this.$store.dispatch('modal/changeNonMemberModalState', false)
  }

  private nonMemberModalClose(): void {
    this.removeStorage('beforePath')
    this.removeStorage('twitterLogin')
    this.modalClose()
  }

  private clickButton(): void {
    this.modalClose()
  }

  private get getStorage(): string {
    return localStorage.getItem('beforePath')
  }

  private setStorage(): void {
    localStorage.setItem('beforePath', this.$route.fullPath)
  }

  private removeStorage(name): void {
    localStorage.removeItem(name)
  }

  private get isReportPath(): boolean {
    return this.$route.path.indexOf('/reports/') > 0
  }

  private get bottomFixedButton(): boolean {
    const page = [
      'companyId',
      'companyId-stories-id',
      'companyId-knowhows-id',
      'members'
    ]
    return page.includes(this.$route.name)
  }

  private get bookshelfPath(): boolean {
    return this.$route.name === 'members-bookshelf'
  }

  private get modal(): boolean {
    const state = this.$store.state.modal.nonMemberModalState
    if (state) this.setStorage()
    return state
  }

  private get displayNav(): boolean {
    return this.$route.name !== 'index'
  }
}
