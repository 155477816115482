export default async ({ app, store }) => {
  await app.router.afterEach((to, from) => {
    const companyPreview = /^\/preview\/companies\//.test(to.fullPath)
    // 記事ページのマイ本棚iconが画面遷移の際にdata取得までに表示が変わる対策としてフラグ管理
    // asyncDataだと必要dataをAPI側に送れず、正確なresponseが取得できなかったため、画面遷移時にfalseして読み込み完了後trueになる
    store.dispatch('bookshelf/backToDefaultIsSavedFlag', false)
    store.dispatch('companies/backToDefaultFollowingFlag', false)
    companyPreview && store.dispatch('companies/getPreviewCompany', to.params)
    if (
      from.params.companyId &&
      from.params.companyId !== to.params.companyId
    ) {
      store.dispatch('movie/clearMovies')
    }
  })
}
