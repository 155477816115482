import initializeMunchkin from '../lib/munchkin'

const TAGS = [
  {
    pathRegexp: /^\/marketo/,
    tagId: '457-ZZV-366'
  },
  {
    pathRegexp: /^\/kaonavi/,
    tagId: '153-JDX-787'
  },
  {
    pathRegexp: /^\/shopro/,
    tagId: '153-JDX-787'
  },
  {
    pathRegexp: /^\/broadleaf/,
    tagId: '153-JDX-787'
  },
  {
    pathRegexp: /.*/,
    tagId: '411-XGV-343' // PR Table
  }
]

function pathToTagId(path) {
  const tag = TAGS.find((tag) => tag.pathRegexp.test(path))
  return tag.tagId
}

export default ({ app }) => {
  if (process.env.NODE_ENV !== 'production') {
    return
  }

  initializeMunchkin(window)
  app.router.afterEach((to, _from) => {
    // 遷移の度にMunchkinを毎回初期化し、パスごとの正しいタグIDで設定し直す。
    // vue-routerのafterEachが呼ばれるタイミングでは、Munchkinの中で呼んでいるlocation.pathnameがまだ新しいパスに切り替わっておらず、
    // リクエストのパラメータのパスの値が古いパスになってしまうため、少し待ってからinitを呼び出す。
    setTimeout(() => {
      /* global Munchkin */
      Munchkin.init(pathToTagId(to.path))
    }, 1000)
  })
}
