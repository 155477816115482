import axios from 'axios'
import camelcaseKeys from 'camelcase-keys'
import authSetting from '~/config/authSetting'
import { Reactions } from '~/types/reactions'
import { API } from '~/types/api'

export const state = () => ({
  reactionButtons: [
    {
      reactionId: 1,
      name: 'いいね',
      class: 'good',
      count: 0,
      clicked: false
    },
    {
      reactionId: 2,
      name: 'スキ',
      class: 'like',
      count: 0,
      clicked: false
    },
    {
      reactionId: 3,
      name: 'わくわく',
      class: 'excited',
      count: 0,
      clicked: false
    },
    {
      reactionId: 4,
      name: 'スゴい！',
      class: 'amazing',
      count: 0,
      clicked: false
    },
    {
      reactionId: 5,
      name: '学びがある',
      class: 'lerning',
      count: 0,
      clicked: false
    }
  ]
})

export const mutations = {
  SET_REACTIONS(state, res: Reactions.CamelReactions) {
    res.map((v: Reactions.Reaction) => {
      state.reactionButtons.filter((d) => {
        if (v.reactionId === d.reactionId) {
          d.count = v.count
          d.clicked = v.clicked
        }
      })
    })
  },
  async SET_CLICK_COUNT(state, payload: Reactions.ClickReaction) {
    const url = process.env.API_URL + `/reactions/${payload.storyId}`
    const postData = {
      reaction_id: payload.id
    }
    if (!state.reactionButtons[payload.i].clicked) {
      state.reactionButtons[payload.i].count++
      state.reactionButtons[payload.i].clicked = true
      try {
        await this.$axios.$post(url, postData, {
          auth: authSetting,
          withCredentials: true
        })
      } catch (e) {
        return e
      }
    } else {
      state.reactionButtons[payload.i].count--
      state.reactionButtons[payload.i].clicked = false
      try {
        await this.$axios.$delete(url, {
          data: postData,
          withCredentials: true
        })
      } catch (e) {
        return e
      }
    }
  }
}

export const actions = {
  async getReactions({ commit }, storyId: string) {
    const reactionsUrl = process.env.API_URL + `/reactions/${storyId}`
    const visitorsUrl = process.env.API_URL + '/visitors'
    await this.$axios
      .$get(reactionsUrl, { auth: authSetting, withCredentials: true })
      .then((response: API.Reactions) => {
        if (!response.visitor_exists) {
          const config = {
            headers: {
              'Auth-Token-Api': process.env.AUTH_TOKEN_API_HEADERS
            },
            auth: authSetting,
            withCredentials: true
          }
          axios.post(visitorsUrl, {}, config)
        }
        commit(
          'SET_REACTIONS',
          camelcaseKeys(response.reactions, { deep: true })
        )
      })
      .catch((err) => {
        return err
      })
  },
  clickReaction({ commit }, payload: Reactions.ClickReaction) {
    commit('SET_CLICK_COUNT', payload)
  }
}
