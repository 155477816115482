import Cookies from 'universal-cookie'
import camelcaseKeys from 'camelcase-keys'
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter'
import authSetting from '~/config/authSetting'
import { Notifications } from '~/types/notifications'
import { API } from '~/types/api'

// componentのtab割当
// notice: 0
// info: 1
export const state = () => ({
  notifications: {},
  activeTab: 0,
  allConfirmed: true
})

export const mutations = {
  SET_NOTIFICATIONS(state, res: Notifications.Notification) {
    const noticesTitle = 'お知らせ'
    const infoTitle = '通知'
    res.values.notices.title = noticesTitle
    res.values.info.title = infoTitle
    res.values.notices.confirmed = true
    res.values.info.confirmed = true
    // dayjsの日付比較を有効化
    this.$dayjs.extend(isSameOrAfter)
    res.values.notices.data.map((v: Notifications.NotificationValueData) => {
      // readAtが空か、v.dateよりも未来日であれば未確認
      if (
        !res.values.notices.readAt ||
        this.$dayjs(v.date).isAfter(res.values.notices.readAt)
      ) {
        res.values.notices.confirmed = false
      }
    })
    res.values.info.data.map((v: Notifications.NotificationValueData) => {
      // readAtが空か、v.dateよりも未来日であれば未確認
      if (
        !res.values.info.readAt ||
        this.$dayjs(v.date).isAfter(res.values.info.readAt)
      ) {
        res.values.info.confirmed = false
      }
    })
    // infoかnoticesどちらかが未確認であればallConfirmedはfalse(未確認)
    !res.values.info.confirmed || !res.values.notices.confirmed
      ? (state.allConfirmed = false)
      : (state.allConfirmed = true)
    if (!res.values.notices.data.length) {
      const nonDataText = `最新の${noticesTitle}はありません`
      res.values.notices.data.push({ text: nonDataText })
      state.activeTab = 1
    }
    if (!res.values.info.data.length) {
      const nonDataText = `最新の${infoTitle}はありません`
      res.values.info.data.push({ text: nonDataText })
      state.activeTab = 0
    }
    state.notifications = res.values
  },
  SET_CONFIRME_STATE(state, notificationType: number) {
    !notificationType
      ? (state.notifications.notices.confirmed = true)
      : (state.notifications.info.confirmed = true)
    if (
      state.notifications.notices.confirmed &&
      state.notifications.info.confirmed
    )
      state.allConfirmed = true
  }
}

export const actions = {
  async getNotifications({ commit, dispatch }, memberId: number) {
    const cookies = new Cookies()
    const url = process.env.API_URL + `/members/${memberId}/notification`
    const config = {
      auth: authSetting,
      withCredentials: true
    }
    if (memberId) {
      try {
        await this.$axios
          .$get(url, config)
          .then((res: API.Notification) => {
            commit('SET_NOTIFICATIONS', camelcaseKeys(res, { deep: true }))
          })
          .catch((err) => {
            // APIレスポンスが401だった場合
            const unauthorized =
              err.response.status === 401 || err.response.status === 404
            if (unauthorized) {
              cookies.set('unauthorized_error', true)
              dispatch('logout')
            }
          })
      } catch (err) {
        return err
      }
    }
  },
  async logout({ dispatch }) {
    const csrfUrl = process.env.API_URL + '/members/get_csrf_token'
    try {
      await this.$axios
        .$get(csrfUrl, {
          auth: authSetting,
          withCredentials: true
        })
        .then((token: API.CSRF) => {
          const csrfToken = token.csrf_token
          dispatch('logoutAction', { csrfToken })
        })
    } catch (e) {
      return e
    }
  },
  async logoutAction(_, payload) {
    const cookies = new Cookies()
    const { csrf } = payload
    const url = process.env.API_URL + '/members/logout'
    const config = {
      headers: {
        'X-CSRF-Token': csrf
      },
      auth: authSetting,
      withCredentials: true
    }
    try {
      await this.$axios
        .$patch(url, {}, config)
        .then(() => {
          // sessionStorageのmemberId削除
          sessionStorage.removeItem('member')
        })
        .then(() => {
          const redirectUrl = `/`
          location.href = redirectUrl
        })
    } catch (e) {
      const unauthorized =
        e.response.status === 401 || e.response.status === 404
      if (unauthorized) {
        cookies.remove('member_id')
        sessionStorage.removeItem('member')
        location.href = '/login'
      }
    }
  },
  updateReadAt({ commit }, notificationType: number) {
    commit('SET_CONFIRME_STATE', notificationType)
  }
}
