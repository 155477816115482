import { Stories } from '~/types/stories'

export const state = () => ({
  histories: {}
})

export const mutations = {
  SET_HISTORIES(state, res: Stories.InfinityLoadArticles) {
    state.histories = res
  },
  ADD_HISTORIES(state, history: Stories.StoryRecords) {
    state.histories.records.push(history)
  }
}

export const actions = {
  changeHistories({ commit }, histories: Stories.InfinityLoadArticles) {
    commit('SET_HISTORIES', histories)
  },
  updateHistories({ commit }, history: Stories.StoryRecords) {
    commit('ADD_HISTORIES', history)
  }
}
