
import { Component, Vue } from 'vue-property-decorator'
import { mdiChevronDown, mdiAccountCircle } from '@mdi/js'
import LogoBtn from '~/components/shared/LogoBtn.vue'
import GlobalMenu from '~/components/layouts/header/menu/GlobalMenu.vue'
import LoginBtn from '~/components/shared/LoginBtn.vue'

@Component({
  components: {
    LogoBtn,
    GlobalMenu,
    LoginBtn
  }
})
export default class SideNav extends Vue {
  private openPanel = []
  private openPanelCategory = []
  private panelArrow = mdiChevronDown
  private accountCircle = mdiAccountCircle

  private get categories() {
    return this.$store.state.tags.categorization || []
  }

  private get member() {
    return (
      this.$store.state.member.memberFollowing || {
        following: { companies: [], categories: [] },
        favoriteStoryIds: []
      }
    )
  }

  private get auth() {
    return this.$store.state.authentication.membersHeader
  }

  private get mypageMenu() {
    return [
      {
        name: 'マイページ',
        path: '/members/mypage',
        icon: this.accountCircle
      },
      {
        name: 'マイ本棚',
        path: '/members/bookshelf',
        // num: this.member.favoriteStoryIds.length,
        num: this.member.favoriteStoryIds
          ? this.member.favoriteStoryIds.length
          : 0,
        icon: 'icon-font-icon_mybook'
      },
      {
        name: '最近見たページ',
        path: '/members/history',
        icon: 'icon-font-icon_history'
      },
      {
        name: 'フォロー中',
        path: '/members/follow',
        icon: 'icon-font-icon_bookmark'
      },
      {
        name: 'プロフィール',
        path: '/members/profile?type=profile',
        icon: 'icon-font-icon_user'
      },
      {
        name: '通知',
        path: '/members/profile?type=notification',
        icon: 'icon-font-icon_bell'
      }
    ]
  }

  private closeMenu() {
    this.$emit('close-menu')
  }
}
