export const state = () => ({
  filterModalState: false,
  nonMemberModalState: false
})

export const mutations = {
  SET_FILTER_MODAL_STATE(state, res) {
    state.filterModalState = res
  },
  SET_NON_MEMBER_MODAL_STATE(state, res) {
    state.nonMemberModalState = res
  }
}

export const actions = {
  changeFilterModalState({ commit }, state: boolean) {
    commit('SET_FILTER_MODAL_STATE', state)
  },
  changeNonMemberModalState({ commit }, state: boolean) {
    commit('SET_NON_MEMBER_MODAL_STATE', state)
  }
}
