
import { Component, Vue } from 'vue-property-decorator'
import Header from '~/components/layouts/Header.vue'
import SideNav from '~/components/layouts/SideNav.vue'
import Footer from '~/components/layouts/Footer.vue'
import NonMemberModal from '~/components/shared/NonMemberModal.vue'
import LogoBtn from '~/components/shared/LogoBtn.vue'
import Logo from '~/components/shared/Logo.vue'
import MessageAfterLogin from '~/components/shared/MessageAfterLogin.vue'
import MixinMemberId from '~/components/mixins/MixinMemberId'

@Component({
  components: {
    Header,
    SideNav,
    Footer,
    NonMemberModal,
    LogoBtn,
    Logo,
    MessageAfterLogin
  },
  middleware: 'redirectAfterLogin',
  mixins: [MixinMemberId]
})
export default class mypageLayout extends Vue {
  private memberId!: number

  private get modal(): boolean {
    const state = this.$store.state.modal.nonMemberModalState
    if (state) this.setStorage()
    return state
  }

  private modalClose() {
    this.$store.dispatch('modal/changeNonMemberModalState', false)
  }

  private nonMemberModalClose(): void {
    this.removeStorage('beforePath')
    this.removeStorage('twitterLogin')
    this.modalClose()
  }

  private clickButton() {
    this.modalClose()
  }

  private mounted() {
    const currentPathName = this.$route.name
    // removeDtorageの除外ページ
    // login後のリダイレクトPathが/members/mypageなのでmypageは除外
    const excludeNames = ['members-mypage']

    // 現在のパスが excludeNames 以外の場合にのみ removeItem を実行
    if (this.getStorage && !excludeNames.includes(currentPathName)) {
      this.removeStorage('beforePath')
      this.removeStorage('twitterLogin')
    }
  }

  private get getStorage(): string {
    return localStorage.getItem('beforePath')
  }

  private setStorage(): void {
    localStorage.setItem('beforePath', this.$route.fullPath)
  }

  private removeStorage(name): void {
    localStorage.removeItem(name)
  }
}
