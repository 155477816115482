import camelcaseKeys from 'camelcase-keys'
import authSetting from '~/config/authSetting'
import { API } from '~/types/api'

export const state = () => ({
  memberId: null,
  memberFollowing: {}
})

export const mutations = {
  SET_MEMBER_FOLLOWING(state, res) {
    state.memberFollowing = res
  },
  RESET_MEMBER_FOLLOWING(state) {
    state.memberFollowing = {}
  },
  UPDATE_FAVORITE_STOY_IDS(state, { storyId, flag }) {
    if (flag === 'add') {
      state.memberFollowing.favoriteStoryIds = [
        ...state.memberFollowing.favoriteStoryIds,
        storyId
      ]
    } else {
      state.memberFollowing.favoriteStoryIds =
        state.memberFollowing.favoriteStoryIds.filter(
          (id: any) => id !== storyId
        )
    }
  },
  UPDATE_FOLLOWING_IDS(state, { item, type, flag }) {
    const key = type === 'company' ? 'companies' : 'categories'
    if (flag === 'add') {
      state.memberFollowing.following[key] = [
        ...state.memberFollowing.following[key],
        item
      ]
    } else {
      state.memberFollowing.following[key] = state.memberFollowing.following[
        key
      ].filter((i: any) => i.id !== item.id)
    }
  }
}

export const actions = {
  async getMemberFollowing({ state, commit }, memberId: number) {
    if (state.memberFollowing.memberId !== memberId) {
      const url = process.env.API_URL + `/members/${memberId}/dashboard`
      const config = {
        auth: authSetting,
        withCredentials: true
      }
      const following: API.Following = await this.$axios.$get(url, config)
      commit('SET_MEMBER_FOLLOWING', camelcaseKeys(following, { deep: true }))
    }
  },
  updateFavoriteStoryIds({ commit, state }, storyId: number) {
    !state.memberFollowing.favoriteStoryIds.includes(storyId)
      ? commit('UPDATE_FAVORITE_STOY_IDS', { storyId, flag: 'add' })
      : commit('UPDATE_FAVORITE_STOY_IDS', { storyId, flag: 'remove' })
  },
  updateFollowing({ commit, state }, { item, type, flag }) {
    const key = type === 'company' ? 'companies' : 'categories'
    const isFollowing = state.memberFollowing.following[key].some(
      (i: any) => i.id === item.id
    )
    if (isFollowing && flag === 'remove') {
      commit('UPDATE_FOLLOWING_IDS', { item, type, flag: 'remove' })
    } else if (!isFollowing && flag === 'add') {
      commit('UPDATE_FOLLOWING_IDS', { item, type, flag: 'add' })
    }
  }
}
