
import { Component, Vue } from 'vue-property-decorator'
import ErrorHeader from '~/components/layouts/error/ErrorHeader.vue'
import ErrorFooter from '~/components/layouts/error/ErrorFooter.vue'

@Component({
  components: {
    ErrorHeader,
    ErrorFooter
  }
})
export default class IndexPage extends Vue {}
