
import { Component, Prop, Vue } from 'vue-property-decorator'
import Logo from '~/components/shared/Logo.vue'
import MixinMemberId from '~/components/mixins/MixinMemberId'

@Component({
  components: {
    Logo
  },
  mixins: [MixinMemberId]
})
export default class HeaderLogo extends Vue {
  @Prop({
    type: Boolean,
    default: false,
    required: false
  })
  private isTop?: boolean

  private memberId!: number

  private get tagName() {
    return this.isTop ? 'h1' : 'div'
  }

  private get auth() {
    return this.memberId
  }
}
